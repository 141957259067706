import Diagnostic from '../diagnostic/diagnostic.js';
import OneTrust from '../one-trust/d3OneTrustService.js';
import Configuration from '../configuration/index.js';
import UserNation from '../user-nation/index.js';
import {
  detectAppleMobileDevices,
  getJsonConfigurationUrl,
  isDisplayAsWebView,
  parseMobileAppConsents,
} from '../utils/utils.js';
import { AnalyticsWrapper } from '../libraries/analytics-wrapper.js';
import { ConsentScriptUrl, cookieCategoriesValues } from '../config.js';
import { shouldUseMobileAppBridge } from '../utils/configuration-helper.js';

let traitsSet = false;
let trackCachedEvents = [];
let pageCachedEvents = [];
let identifyCachedEvents = [];
let oneTrust = undefined;
let configuration = undefined;
let isWrapperReady = false;

export class DdnaWrapper {
  constructor() {
    window.AnalyticsWrapper = new AnalyticsWrapper();
  }

  async readConfiguration() {
    const jsonReq = await fetch(getJsonConfigurationUrl());
    const jsonConfig = await jsonReq.json();

    // If configuration present, merge it with the default
    if (window.GlobalConfiguration) {
      window.GlobalConfiguration = Object.assign(jsonConfig, window.GlobalConfiguration);
    } else {
      window.GlobalConfiguration = jsonConfig;
    }
  }

  async init() {
    window.ddnawrapper = window.ddnawrapper || {};
    window.ddnawrapper.isReady = this.isReady;
    window.ddnawrapper.analytics = window.ddnawrapper.analytics || {};
    window.ddnawrapper.analytics.track = this.track;
    window.ddnawrapper.analytics.page = this.page;
    window.ddnawrapper.analytics.identify = this.identify;
    window.ddnawrapper.analytics.reset = this.reset;
    window.ddnawrapper.analytics.sendCachedEventsWithTraits = this.sendCachedEventsWithTraits;
    window.ddnawrapper.analytics.consentGiven = this.consentGiven;
    window.ddnawrapper.analytics.setImplementationProvider = this.setImplementationProvider;

    window.ddnawrapper.user = window.ddnawrapper.user || {};
    window.ddnawrapper.user.anonymousId = this.anonymousId;

    // Diagnostic
    let diagnostic = new Diagnostic();
    diagnostic.init();

    window.addEventListener('analytics-ready', async () => {
      await this.setTraitsForAllEvents();
    });

    await this.readConfiguration();

    // User nation
    let userNation = new UserNation();
    await userNation.init();

    // Configuration and Segment
    configuration = new Configuration();
    await configuration.init();

    oneTrust = new OneTrust();
    oneTrust.initFunctionsForThirdParties();

    const injectOneTrust = window.GlobalConfiguration.injectOneTrust;

    window.ddnawrapper?.diagnostic?.log(`Inject one trust: ${injectOneTrust}`);

    // Initialize one trust when it's not defined or it's defined and it's set to 'true', otherwise don't initialize
    if (injectOneTrust) {
      // In webviews we are sure the user already gave consent, therefore we initialize OneTrust with needed consent for Segment
      if (isDisplayAsWebView()) {
        await this.handleWebviewConsent();
      } else {
        await oneTrust.init();
        await this.loadCookiesBannerHideStylesScript();
        await this.loadConsentScript();
      }
    }

    isWrapperReady = true;
    window.dispatchEvent(new CustomEvent('ddna-wrapper-ready'));
    window.ddnawrapper?.diagnostic?.log(`ddna wrapper is ready`);
  }

  async handleWebviewConsent() {
    if (shouldUseMobileAppBridge()) {
      window.ddnawrapper?.diagnostic?.log('OCSMobileAppBridge: handle new webview logic');

      return new Promise((resolve) => {
        /**
         * Consents callback called by the mobile app
         * @param {any} consents data sent by the app
         * @returns {Promise<void>}
         */
        window.getConsentsCallback = async function (consents) {
          window.ddnawrapper?.diagnostic?.log(
            `OCSMobileAppBridge: received response in consents callback: ${consents}`
          );

          if (consents) {
            const parsedConsents = parseMobileAppConsents(consents);

            /** @type {Record<string, string>} */
            const finalConsent = {};

            cookieCategoriesValues.forEach((category) => {
              const consentFromApp = parsedConsents[category];

              if (consentFromApp !== undefined) {
                finalConsent[category] = consentFromApp === true ? '1' : '0';
              } else {
                finalConsent[category] = '0';
              }
            });

            await window.ddnawrapper.analytics.consentGiven(finalConsent);

            resolve();
          } else {
            window.ddnawrapper?.diagnostic?.log(
              `OCSMobileAppBridge: consents not well formed -> pass fallback`
            );

            // Fallback if response is not received
            await window.ddnawrapper.analytics.consentGiven({
              C0001: '1',
              C0002: '1',
              C0003: '0',
              C0004: '0',
              C0005: '0',
            });

            resolve();
          }
        };

        window.OCSMobileAppBridge.callbacks['getConsentsCallback'] = window.getConsentsCallback;

        window.OCSMobileAppBridge.callHandler('getConsents', null, 'getConsentsCallback');
      });
    } else {
      await this.consentGiven({ C0001: '1', C0002: '1', C0003: '0', C0004: '0', C0005: '0' });
    }
  }

  isReady() {
    return isWrapperReady;
  }

  anonymousId() {
    const fetchAnonymousId = () => {
      window.ddnawrapper?.diagnostic?.log(`'anonymousId' method invoked`);

      
      return window.AnalyticsWrapper?.user().anonymousId();
    };

    return new Promise((resolve, reject) => {
      try {
        if (window.AnalyticsWrapper.userIsReady()) {
          return resolve(fetchAnonymousId());
        } else {
          if(window.ddnawrapper.consent.isConsentGiven() && !window.ddnawrapper.consent.isTrackingConsentGiven()) {
            resolve("anonymous_user");
          } else {
            window.addEventListener('analytics-ready', () => resolve(fetchAnonymousId()));
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  async sendCachedEventsWithTraits() {
    if (traitsSet) {
      if (identifyCachedEvents.length > 0) {
        await this.sendIdentifyCachedEvents();
      }

      if (pageCachedEvents.length > 0) {
        await this.sendPageCachedEvents();
      }

      if (trackCachedEvents.length > 0) {
        await this.sendTrackCachedEvents();
      }
    }
  }

  async sendPageCachedEvents() {
    while (pageCachedEvents?.length > 0) {
      const element = pageCachedEvents.pop();
      await window.AnalyticsWrapper?.page(element.category, element.name, element.properties);
    }
  }

  async sendTrackCachedEvents() {
    while (trackCachedEvents?.length > 0) {
      const element = trackCachedEvents.pop();
      await window.AnalyticsWrapper?.track(element.event, element.properties);
    }
  }

  async sendIdentifyCachedEvents() {
    while (identifyCachedEvents?.length > 0) {
      const element = identifyCachedEvents.pop();
      await window.AnalyticsWrapper?.identify(element.userId, element.traits);
    }
  }

  async track(event, properties) {
    window.ddnawrapper?.diagnostic?.log(`'track' method invoked, with event name '${event}'.`);
    if (!traitsSet) {
      trackCachedEvents.push({ event: event, properties: properties });
    } else {
      return await window.AnalyticsWrapper?.track(event, properties);
    }
  }

  async page(category, name, properties) {
    window.ddnawrapper?.diagnostic?.log(`'page' method invoked with event name '${name}'`);
    if (!traitsSet) {
      pageCachedEvents.push({ category: category, name: name, properties: properties });
    } else {
      return await window.AnalyticsWrapper?.page(category, name, properties);
    }
  }

  async consentGiven(consent) {
    window.ddnawrapper?.diagnostic?.log(
      `'consentGiven' method invoked, with data '${JSON.stringify(consent)}'.`
    );

    if (consent) {
      oneTrust.externalConsentGiven(consent);
    }
  }

  async identify(userId, traits = {}) {
    window.ddnawrapper?.diagnostic?.log(
      `'identify' method invoked, with userId '${JSON.stringify(userId)}'`
    );
    if (!traitsSet) {
      identifyCachedEvents.push({ userId: userId, traits: traits });
    } else {
      return await window.AnalyticsWrapper?.identify(userId, traits);
    }
  }

  async reset() {
    window.ddnawrapper?.diagnostic?.log(`'reset' method invoked`);
    return window.AnalyticsWrapper?.reset();
  }

  async setTraitsForAllEvents() {
    traitsSet = true;
    window.AnalyticsWrapper.addSourceMiddleware(({ payload, next, integrations }) => {
      payload.obj.properties = payload.obj.properties || {};
      payload.obj.properties.traits = window.AnalyticsWrapper.user().traits();
      payload.obj.properties.authenticated = this.isAuthenticationCookieExists();

      payload.obj.context.os = payload.obj.context.os || {};
      payload.obj.context.os.name = this.getOsName();

      next(payload);
    });

    await this.sendCachedEventsWithTraits();
  }

  /**
   * Set the implementation_provider value for the analytics
   * @param {string} implementationProvider
   */
  setImplementationProvider(implementationProvider) {
    window.AnalyticsWrapper.addSourceMiddleware(({ payload, next, integrations }) => {
      payload.obj.properties['implementation_provider'] = implementationProvider;
      next(payload);
    });
  }

  getOsName() {
    if (isDisplayAsWebView()) {
      const iOS = detectAppleMobileDevices();
      return iOS ? 'iOS' : 'android';
    }

    return null;
  }

  isAuthenticationCookieExists() {
    return document?.cookie?.split(';')?.some((it) => it.trim()?.startsWith('glt')) ?? false;
  }

  async loadConsentScript() {
    let globalConfiguration = window.GlobalConfiguration || {};

    if (document.querySelectorAll(`script[src="${ConsentScriptUrl}"]`).length == 0) {
      let bodyscript = document.createElement('script');
      bodyscript.setAttribute('type', 'text/javascript');
      bodyscript.setAttribute('src', ConsentScriptUrl);
      bodyscript.setAttribute('charset', 'UTF-8');
      bodyscript.setAttribute('crossorigin', 'anonymous');
      bodyscript.setAttribute('data-domain-script', globalConfiguration.oneTrustDataDomainScript);

      document.head.appendChild(bodyscript);
    } else {
      window.ddnawrapper?.diagnostic?.log(
        `Consent script '${ConsentScriptUrl}' was preloaded before init on wrapper.`
      );
    }
  }

  async loadCookiesBannerHideStylesScript() {
    const cssStyle = '#onetrust-consent-sdk { display: none; }';
    let bodyscript = document.createElement('style');
    bodyscript.setAttribute('type', 'text/css');
    bodyscript.setAttribute('id', 'one-trust-hide-consent')    

    if (bodyscript.styleSheet) {
      // This is required for IE8 and below.
      bodyscript.styleSheet.cssText = cssStyle;
    } else {
      bodyscript.appendChild(document.createTextNode(cssStyle));
    }

    document.head.appendChild(bodyscript);
  }
}

let wrapper = new DdnaWrapper();
wrapper.init();
